/* global _ */
import {name, mixins, props, data, EventBus} from './config'

import MainService from '../../../../../services/main'

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
      currentStep: 'final',
      resendOrderConfirmationCount: -1,
      resendRegistrationVerificationCount: -1,
      email: null,
      invoiceUrl: null,
      showVerificationPrompt: false,
      isProcessing: false,
      showOrderEmailSuccess: false,
      showUserVerificationEmailSuccess: false,
    }
  },

  computed: {
    waiverEligible() {
      return _.some(this.items || {}, x => x.needsWaiver)
    },
    itemClasses() {
      return _.uniq(_.values(_.mapValues(this.items || {}, x => 'product-type-' + x.type)))
    },
  },

  created() {
    this.email = _.get(this.order, 'user.data.email', '')
    this.invoiceUrl = _.get(this.order, 'invoice_url', '')
    const user = this.user
    if (user && !user.verified) {
      this.showVerificationPrompt = true
    }
  },

  async mounted() {
    this.resendOrderConfirmationCount = 0
    this.resendRegistrationVerificationCount = 0
    if (this.user) {
      await this.fetchUser()
    }
  },

  methods: {
    async notifyOrder(count) {
      EventBus.$emit('cart.before.notifyOrder')
      try {
        if (!_.isUndefined(count)) {
          this.resendOrderConfirmationCount = count
        }
        this.isProcessing = true
        this.showOrderEmailSuccess = false
        await MainService.notifyOrder(this.order)
        EventBus.$emit('cart.notifyOrder')
        this.resendOrderConfirmationCount++
        this.showOrderEmailSuccess = true
      } catch (e) {
        EventBus.$emit('cart.notifyOrder.error', e)
        this.alertAsync('Error resending order confirmation. \nPlease try again.')
      }
      this.isProcessing = false
    },
    async sendUserEmailVerification(count) {
      EventBus.$emit('cart.before.sendConfirmation')
      try {
        if (!_.isUndefined(count)) {
          this.resendRegistrationVerificationCount = count
        }
        this.isProcessing = true
        this.showUserVerificationEmailSuccess = false
        await MainService.sendConfirmation(this.email)
        EventBus.$emit('cart.sendConfirmation')
        this.resendRegistrationVerificationCount++
        this.showUserVerificationEmailSuccess = true
      } catch (e) {
        EventBus.$emit('cart.sendConfirmation.error', e)
        this.alertAsync('Error resending confirmation. \nPlease try again.')
      }
      this.isProcessing = false
    },
    showPaymentDetails() {
    this.emit('showPaymentDisabledDetails')
    },
    getComponentExtraClasses() {
      return this.itemClasses
    },
    emitConfirmedEvent(e) {
      this.$$emit('cart.final.confirm.click', () => this, e)
    }
  }
}
