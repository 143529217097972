/*global _ */
import formProps from '../../../../props/form'
import FormMixin from '../../../../mixins/form'
import UpsellProductMixin from '../../../../mixins/shop/upsell-product'
import ShopLocationMixin from '../../../../mixins/shop/location'
import ShopFailingLocationMixin from '../../../../mixins/shop/failing-location'
import ShopCardMixin from '../../../../mixins/shop/card/custom-amount'
import FieldsMixin from '../../../../mixins/form/custom-field-properties'
import ComponentMixin from '../../../../mixins/components/complex-reactive'
import ProductsFilterMixin from '../../../../mixins/store/products-filter'
import MessagesMixin from './messages'
import {
  Validators,
  setValidationRule,
  parseValidationSetup,
  parseValidations,
  truthyRule, ref, req,
  withParams,
} from '../../../../lib/validations'
const {required, helpers, maxValue} = Validators

import {templify} from '../../../../lib/utils'
import {getLocationsFullSync, getLocationsSync} from '../../../../lib/formatters'

const EventBus = window.VueEventBus

const validations = {
  productId: setValidationRule('fields.productId'),
  termsAgreed: setValidationRule('fields.termsAgreed', {
    truthy: {error: 'Please agree to the Terms & Conditions', rule: truthyRule},
  }),
  quantity: {
    name: 'fields.quantity',
    items: {}
  },
  location_id: {
    name: 'fields.location_id',
    items: {}
  },
  custom_amount: {
    name: 'fields.custom_amount',
    items: {}
  },
}

export default {
  name: 're-buy-card',
  mixins: [
    ComponentMixin,
    FormMixin,
    ShopCardMixin,
    UpsellProductMixin,
    FieldsMixin,
    ProductsFilterMixin,
    ShopLocationMixin,
    ShopFailingLocationMixin,
    MessagesMixin
  ],
  props: {
    ...formProps,
    translations: {},
    settings: {},
    token: {},
    item: {},
    termsAgreed: {},
    editMode: {
      type: Boolean,
      default: false
    },
    editModeData: {
      type: Object,
      default: null
    },

    title: {
      type: String,
      default: 'Buy card'
    },
    showTitle: {
      type: [Boolean, Number, String],
      default: true
    },
    subTitle: {
      type: String,
      default: null,
    },
    showSubTitle: {
      type: [Boolean, Number, String],
      default: false
    },
    notice: {
      type: String,
      default: null
    },
    showNotice: {
      type: [Boolean, Number, String],
      default: false
    },
    showFormLabels: {
      type: [Boolean, Number, String],
      default: true,
    },
    hasPreviewCard: {
      type: Boolean,
      default: false,
    },
    previewButtonTitle: {
      type: String,
      default: 'Next',
    },
    previewCardBoxTitle: {
      type: String,
      default: 'Preview: The card email will look like this'
    },
    previewEditButtonTitle: {
      type: String,
      default: 'Edit'
    },
    previewCardBoxHtml: {
      default: null,
    },

    noAgreeToTerms: {
      type: Boolean,
      default: false
    },
    showGroupBoxTitle: {
      type: Boolean,
      default: true
    },
    groupBoxTitle: {
      type: String,
      default: 'Select your amount'
    },
    showProductSelector: {
      type: Boolean,
      default: true
    },
    productLabelFormat: {
      type: [String, Array, Object],
      default: null
    },
    amountClass: {
      type: [String, Array, Object],
      default: null
    },
    showAmountTitle: {
      type: Boolean,
      default: false
    },
    amountTitle: {
      type: String,
      default: 'Select your amount'
    },
    showAmountSubtitle: {
      type: Boolean,
      default: false
    },
    amountSubtitle: {
      type: String,
      default: 'Please choose the gift card amount.'
    },
    amountBoxLabel: {
      type: String,
      default: 'Amount'
    },
    amountSelectDefault: {
      type: String,
      default: 'Select a Card'
    },
    quantityClass: {
      type: [String, Array, Object],
      default: null
    },
    showQuantityTitle: {
      type: Boolean,
      default: false
    },
    quantityTitle: {
      type: String,
      default: 'Select quantity'
    },
    showQuantitySubtitle: {
      type: Boolean,
      default: false
    },
    quantitySubtitle: {
      type: String,
      default: 'Select quantity'
    },
    cardsList: {
      type: [Array, Object],
      default: null,
    },
    noQuantity: {
      type: Boolean,
      default: false
    },
    quantityLabel: {
      type: String,
      default: 'Quantity'
    },

    submitButtonTitle: {
      type: String,
      default: 'Add to Card'
    },
    submitButtonClasses: {
      type: [String, Array, Object],
      default: null
    },
    updateButtonTitle: {
      type: String,
      default: 'Update Cart'
    },
    updateButtonClasses: {
      type: [String, Array, Object],
      default: null
    },

    // insert to cart props
    showInsertToCartButton: {
      type: [Boolean, Number, String],
      default: false
    },
    insertToCartButtonClass: {
      type: [String, Array, Object],
      default: null
    },
    insertToCartButtonTitle: {
      type: String,
      default: 'Add to cart'
    },

    // Use location
    useLocation: {
      type: [Boolean, Number, String],
      default: false
    },
    filterProductByLocation: {
      type: [Boolean, Number, String],
      default: 'strict'
    },
    hideOtherFieldsOnNoLocation: {
      type: [Boolean, Number, String],
      default: true
    },
    locationInputOptions: {
      type: [Object],
      default() {
        return {}
      }
    },
    rememberLocation: {
      type: [Boolean, Number, String],
      default: true
    },

    maxQuantity: {},
    maxQuantityByLocation: {},
    userCardsQuantity: {},
    userPerLocationCardsQuantity: {},

  },
  data() {
    return {
      fields: {
        termsAgreed: 'no',
        productId: null,
        quantity: 1,
        product: null,
        location_id: null,
        custom_amount: null,
      },
      isAddingToCart: false,
      cartItemAdded: 0,
      showPreview: false,

      validations: null,
      allLocations: {},
    }
  },
  validations() {
    return {
      fields: this.applyValidations()
    }
  },

  async created() {
    this.init()
    this.allLocations = getLocationsSync()
    this.setupValidations()
    EventBus.$emit('buy-card.create')

    if (this.editMode) {
      EventBus.$emit('buy-card.before.edit', this.editModeData)

      const productId = _.get(
        this.editModeData,
        'attributes.original_product.id',
        _.get(
            this.editModeData,
          'attributes.product.id',
            this.editModeData.payload.product_id
          )
      )

      const itemOptions = _.mapKeys(this.editModeData.payload.options || {}, (item, key) => {
        return key === this.customAmountCartItemProperty ? 'custom_amount' : key
      })
      this.fields = {
        ...itemOptions,
        termsAgreed: 'yes',
        quantity: this.editModeData.count,
        productId,
      }
      EventBus.$emit('buy-card.edit', this.fields)
    }
  },
  async mounted() {
    this.$nextTick()
    if (this.maxQuantity || this.maxQuantityByLocation) {
      const quantity = _.get(this.$v, 'fields.quantity')
      if (quantity) {
        quantity.$touch()
      }
    }
  },
  computed: {
    cards() {
      const location = this.fields.location_id
      const filters = {}
      if (location && this.useLocationFilter) {
        if (this.useStrictLocationFilter) {
          filters.location = {location, strict: true}
        } else {
          filters.location = location
        }
      }
      const cards = this.applyPointsOnProducts(
        this.autoApplyFilterOnProducts(_.values(this.cardsList || []), filters)
      )

      return _.map(cards, item => {
        item.$numbers = {
          price: _.toNumber(item.price),
          priceRound: _.round(item.price),
        }
        return item
      })
    },
    showPreviewCard() {
      return this.hasPreviewCard && this.showPreview
    },
    showAgreeToTermsBox() {
      return !this.noAgreeToTerms
    },
    disabledAmountBox() {
      return this.showAgreeToTermsBox && this.fields.termsAgreed !== 'yes'
    },
    disabledNextButton() {
      const invalid = this.isAnyInvalid
      return invalid ||
        this.disabledAmountBox ||
        _.isNil(this.fields.productId) ||
        !this.fields.quantity
    },
    amountSelectLabel() {
      return this.showFormLabels ? this.amountBoxLabel : null
    },
    quantityInputLabel() {
      return this.showFormLabels ? this.quantityLabel : null
    },
    showInsertButton() {
      return !this.editMode && this.showInsertToCartButton
    },
    disableInsertButton() {
      return this.disabledNextButton
    },
    disableSubmitButton() {
      return this.disabledNextButton &&
        !(this.showInsertToCartButton && this.cartItemAdded)
    },
    selectedProduct() {
      const searchId = this.fields.productId
      if (searchId) {
        return _.find(this.cards, (card) => card.id == searchId)
      }
      return null
    },
    showLocationsInput() {
      return this.useLocation
    },
    useLocationFilter() {
      return this.showLocationsInput && this.filterProductByLocation
    },
    useStrictLocationFilter() {
      return this.filterProductByLocation === 'strict'
    },
    hideNonLocationFields() {
      return this.useLocationFilter && !this.fields.location_id && this.hideOtherFieldsOnNoLocation
    },
    locationInputContainerAttrs() {
      return {
        class: this.getFieldContainerClasses('location_id')
      }
    },
    locationInputAttrs() {
      const defaults = this.locationInputOptions || {}
      if (!_.has(defaults, 'blankOption')) {
        defaults.blankOption = 'Select location'
      }
      defaults.checkInvalid = true
      return this.getLocationFieldProperties('location_id', {defaults})
    },
    canImmediatelyValidateQuantity() {
      return !this.isAddingToCart
    },
    locationClassName() {
      const id = this.fields.location_id

      if (id) {
        const location = _.get(this.allLocationDetails[id], 'name') || this.allLocations[id]
        return _.kebabCase(location)
      }
      return ''
    },
    infoBoxStyles() {
      const definedStyles = this.$$s('infoBox.styles', {})
      const locationDefs = this.$$s('infoBox.locationStyles', {})
      const location = this.locationClassName
      const locationStyles = location ? (locationDefs[location] || locationDefs.default || {}) : {}
      const styles = {
        ...definedStyles,
        ...locationStyles
      }
      return styles
    }
  },
  watch: {
    showPreview() {
     this.lazyScrollTo('.re-page')
    },
    'fields.productId'(newVal) {
      EventBus.$emit('buy-card.change.productId', newVal)
      EventBus.$emit('buy-card.change.product', this.selectedProduct)
      EventBus.$emit('buy-card.change.product-with-location', {
        product: this.selectedProduct,
        location_id: this.fields.location_id,
      })
      this.resetCustomAmountProduct(this.cards, this.editMode ? newVal: null)
    },
    'fields.quantity'(newVal) {
      EventBus.$emit('buy-card.change.quantity', newVal)
    },
    'fields.location_id': {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          this.setShopLocationId(val)
        })
      }
    },
  },
  methods: {
    init() {
      this.isAddingToCart = false
      const agreed = this.noAgreeToTerms ? 'yes' : (this.termsAgreed || 'no')
      this.fields.termsAgreed = agreed
      this.fields.productId = null
      this.fields.quantity = 1
      this.fields.product = null
      const preferredLocation = _.get(this.userDataMeta, 'preferred_location_id')
      const shopLocation = this.shopLocationId
      if (this.rememberLocation && shopLocation) {
        this.fields.location_id = shopLocation || this.fields.location_id
      } else {
        this.fields.location_id = preferredLocation
      }

      this.fields.custom_amount = null
      this.resetCustomAmountProduct(this.cards)
      this.resetFieldsValidations()
    },
    resetFieldsValidations() {
      this.$v.$reset()
    },
    enableFieldsValidations() {
      this.$v.$touch()
    },
    setupValidations() {
      const items = parseValidationSetup(
        this.parseFormValidations(validations, this.$$s('form.fields')),
        this
      )
      if (!this.isCustomAmountProduct) {
        items.custom_amount.items = {}
      } else {
        this.setupCustomAmountValidations(items.custom_amount.items)
        if (!this.showQuantityForCustomAmountProduct) {
          if (!this.maxQuantity || !this.maxQuantityByLocation) {
            items.quantity.items = {}
          }
        }
      }
      if (!this.useLocation) {
        items.location_id.items = {}
      } else {
        if (this.validateFailingLocation && this.setupFailingLocationValidations) {
          this.setupFailingLocationValidations(items.location_id.items)
        }
      }

      if (!items.quantity.items) {
        items.quantity.items = {}
      }
      if (!items.quantity.name) {
        items.quantity.name = 'fields.quantity'
      }
      this.setupMaxQuantityValidations(items.quantity.items)
      this.validations = items
    },
    setupMaxQuantityValidations(quantityItems) {
      const max = this.maxQuantity
      const userCount = this.userCardsQuantity || 0
      const limit = max - userCount

      if (max) {
        const error = templify(
          this.$$t(
            'form.fields.quantity.validationMessages.max',
            'You have reached the limit of {max} cards.'
          ),
          {max, ...this.fields, count: userCount},
          ['{', '}']
        )
        quantityItems.max = {error, rule: maxValue(limit)}
      }

      const maxPerLocation = this.maxQuantityByLocation
      if (maxPerLocation) {
        const error = templify(
          this.$$t(
            'form.fields.quantity.validationMessages.maxLocation',
            `You have reached the limit of :max cards for
             location - :locationName.`
          ),
          {max: maxPerLocation, ...this.fields},
          ['{', '}']
        )

        const rule = withParams(
          {
            ...this.fields,
            max: maxPerLocation,
            count: this.userPerLocationCardsQuantity[this.fields.location_id] || 0,
            locationName: this.allLocations[this.fields.location_id] || '',
          },
          function (value, parentVm) {
            const selectedLocation = this.fields.location_id
            const count = this.userPerLocationCardsQuantity[selectedLocation] || 0
            if (value) {
                const limit = maxPerLocation - count
                return value <= limit
              }
            return true
          }
        )
        quantityItems.maxLocation = {error,  rule}
      }
    },
    applyValidations() {
      this.setupValidations()
      let items = parseValidations(this.validations)
      return items
    },
    async addCardToCart(preventCheckout) {
      if (!preventCheckout && this.isAnyInvalid) {
        this.dontAddCardToCartAndGo()
        return
      }
      EventBus.$emit('buy-card.before.add-to-cart')
      this.fields.product = this.selectedProduct
      this.isAddingToCart = true
      this.resetFieldsValidations()
      await EventBus.$$emitAsync('buy-card.add-to-cart', this.fields, {preventCheckout})
      if (preventCheckout) {
        this.isAddingToCart = false
        this.enableFieldsValidations()
      }
    },
    async insertCardToCart() {
      await this.addCardToCart(true)
      this.cartItemAdded += 1
    },

    dontAddCardToCartAndGo() {
      this.$router && this.$router.push({ name: 'cart' })
    },
    async addCardToCartAndGo() {
      await this.addCardToCart()
      this.$router && this.$router.push({ name: 'cart' })
    },
    openCardPreview() {
      this.showPreview = true
      EventBus.$emit('buy-card.preview.open')
    },
    hideCardPreview() {
      this.showPreview = false
      EventBus.$emit('buy-card.preview.back')
    },
    getFieldClasses(field, source = {}, mergeData = {}) {
      const data = {...source}
      const path = `form.fields.${field}`
      const def = this.$$s(`${path}.classes`, this.$$s(`${path}.attr.classes`))
      const classes = this.parseReactiveAttrs(def, data || {})
      return [
        classes,
        mergeData
      ]
    },
    getProductIdFieldAttrs(item) {
      return {
        'class': this.getFieldClasses(
          'productId',
          item, {
            active: this.fields.productId == item.id
          }
        ),
        name: 'amount',
        key: item.id,
        id: `amount${item.id}`,
        radioValue: item.id,
        context: this,
        validations: this.validations.productId,
        label: this.productLabelFormat ?
         this.$$templify(this.productLabelFormat, item) : item.title,
      }
    },
    getComponentExtraClasses() {
      return [
        this.locationClassName ? 'location-' + this.locationClassName : ''
      ]
    }
  }
}
