import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  step: {},

  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },
  allowGuestCheckout: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('allowGuestCheckout', this.$options.$$s('cart-list.allowGuestCheckout', false))
    }
  },
  noAuthCheck: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('noAuthCheck', this.$options.$$s('cart-list.noAuthCheck', true))
    }
  },
  useLocationBasedCart: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('useLocationCart', true)
    }
  },
  guestCheckoutTypes: {
    type: [Object, Array, String],
    default() {
      return this.$options.$$s('guestCheckoutTypes', this.$options.$$s('cart-list.guestCheckoutTypes', []))
    }
  },
  skipBillingIfNoPayment: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('skipBillingIfNoPayment', this.$options.$$s('cart-list.skipBillingIfNoPayment', false))
    }
  },
  validationMessage: {
    type: String,
    default() {
      return this.$options.$$t('validation.message', 'Validating')
    }
  },

  listComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.cart-list', 're-cart-list') //@change: cart-list.component
    }
  },
  emptyComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.cart-empty', 're-cart-empty') //@change: cart-empty.component
    }
  },
  billingComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.cart-billing', 're-cart-billing') //@change: cart-billing.component
    }
  },
  finalComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.cart-final', 're-cart-final') //@change: cart-final.component
    }
  },
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
