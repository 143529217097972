/*global _, Vue */
// import lang from '../lib/lang'
export default {
  i18n: {},
  translations: null,
  beforeCreate() {
    const propTranslations = _.get(this.$options && this.$options.propsData || {}, 'translations')
    let translations = null
    if (propTranslations) {
      translations = propTranslations
    } else {
      translations = this.$options && this.$options.translations
    }
    if (!translations)  {
      translations = this.$parent && this.$parent.translations
    }
    this.$$setTranslations(translations)
  },
  computed: {
    $$$t() {
      return this.$t('this')
    }
  },
  methods: {
    $$t(path, defaultValue, ...rest) {
      return this.$options.$$t(path, defaultValue, ...rest)
    },
    $$tc(path, defaultValue, ...rest) {
      return this.$options.$$tc(path, defaultValue, ...rest)
    },
    $$d(path, defaultValue, ...rest) {
      return this.$options.$$d(path, defaultValue, ...rest)
    },
    $$n(path, defaultValue, ...rest) {
      return this.$options.$$n(path, defaultValue, ...rest)
    },
  }
}
