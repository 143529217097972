/*global _, $ */
import {fv} from '../../../../lib/utils'
import BaseSelectMixin from '../re-select/mixin'
const mixins = [BaseSelectMixin]
import Select2 from 'v-select2-component'
const components = {Select2}
import defaultSettings from './settings'
//import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css'

export default {
  name: 're-select2',
  components,
  mixins,
  props: {
    tags: {
      type: [Boolean, Number, String, Array],
      default: false
    },
    multiple: {
      type: [Boolean, Number, String, Array],
      default: false
    },
    settingsName: {
      type: String,
      default: 'default',
    },
    settings: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: [Number, String],
      default: null
    },
    dropdownParent: {
      default: null
    },
    resetScrollBlock: {
      type: [Boolean, Number, String],
      default: false
    },
    readonlyMultiInput: {
      default: false
    },
    // hack fix: In some mobile devices, the dropdown, for multi option, closes immediately after opening
    // This happens just after the onscreen keyboard is shown
    // The issue can be fixed by making the input readonly thereby no onscreen keyboard is shown
    readonlyMultiInputInTouch: {
      default: false
    },
    // hack fix: In some cases, the dropdown does not show even though open even is fired after unselect
    // use preventOpenAfterClear=true to prevent opening of dropdown after removal of selection
    preventOpenAfterClear: {
      default: false
    }
  },
  data() {
    return {
      defaultSettings
    }
  },
  computed: {

  },
  watch: {

  },
  async mounted() {
    await this.$nextTick()
    if (this.readonlyMultiInput) {
      await this.applyReadonlyMultiInput()
    }
    if (this.readonlyMultiInputInTouch) {
      await this.applyReadonlyMultiInput(true)
    }
    if (this.preventOpenAfterClear) {
      await this.applyPreventOpenAfterClear()
    }
  },
  methods: {
    applyPreventOpenAfterClear() {
      // events:
      //  select2:opening, select2:open,
      //  select2:closing, select2:close
      //  select2:selecting, select2:select
      //  select2:unselecting, select2:unselect
      //  select2:clearing, select2:clear
      const ref = this.$refs.select2
      if (ref) {
        // hack fix: In some cases, the dropdown does not show even though open even is fired after unselect
        // use preventOpenAfterClear=true to prevent opening of dropdown after removal of selection
        let justUnselected = false
        const select2 = ref.select2
        select2.on('select2:opening', (e) => {
          // debugLog("SELECT 2 opening")
          if (justUnselected) {
            // debugLog("PREVENT SELECT 2 opening - just unselected")
            justUnselected = false
            e.preventDefault()
            return false
          }
        })
        select2.on('select2:unselect', () => {
          justUnselected = true
          // debugLog("SELECT 2 unselect")
          setTimeout(() => {
            justUnselected = false
          }, 1000)
        })
      }
    },
    applyReadonlyMultiInput(touchOnly = false) {
      const el = this.$el
      const $el = $(el)
      const setReadonly = () => {
        $el.find('.select2-search__field').attr('inputmode', 'none')
        $el.find('.select2-search__field').prop('readonly', true)
      }
      if (!touchOnly) {
        setReadonly()
      }
      if (touchOnly) {
        $el.on('touchstart', setReadonly)
        $el.on('touchend', setReadonly)
      }
    },
    getSettings() {
      let settings = _.cloneDeep(this.settings)
      if (_.isEmpty(settings)) {
        settings =
          _.merge(
            {},
            this.defaultSettings[this.settingsName] || this.defaultSettings['default'],
            {
              tags: this.tags,
              multiple: this.multiple,
              selectionCssClass: this.inputClass,
            },
            this.$$attrs(),
          )
      }

      if (settings.multiple) {
        settings.dropdownCssClass = fv(settings.dropdownCssClass, 'select2-dropdown--multiple')
        settings.containerCssClass = fv(settings.containerCssClass, 'select2-container--multiple')
        // settings.selectionCssClass = fv(settings.selectionCssClass, 'select2-selection--multiple')
      }

      if (_.isNil(settings.width) && !_.isNil(this.width)) {
        settings.width = this.width
      }

      let parent = this.dropdownParent
      if (parent) {
        if (parent === 'self') {
          parent = this.$el || (this.$parent && this.$parent.$el) || (this.context && this.context.$el)
        }
        if (parent === 'parent') {
          parent = (this.$parent && this.$parent.$el) || (this.context && this.context.$el) || this.$el
        }
        if (parent) {
          settings.dropdownParent = parent
        }
      }
      return settings
    },
    getOptions() {
      return _.map(this.optionsList || [], (o, index, all) => {
        return {
          id: this.getOptionValue(o, index, all),
          text: this.getOptionLabel(o, index, all),
          ...this.getOptionAttrs(o, index, all)
        }
      })
    },
    onInput(value) {
      this.isDirtyState = true
      this.model = value
    },
    onDropdownOpen(e) {
      if (this.resetScrollBlock) {
        const evt = "scroll.select2"
        $(e.target).parents().off(evt)
        $(window).off(evt)
      }
    }
  }
}
