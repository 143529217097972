export const SET_STORE_CONFIG = 'SET_STORE_CONFIG'
export const SET_DEFAULT = 'SET_DEFAULT'
export const RESET_DEFAULTS = 'RESET_DEFAULTS'
export const MERGE_DEFAULT = 'MERGE_DEFAULT'

export const SET_JOB = 'SET_JOB'
export const SET_JOB_CONFIG = 'SET_JOB_CONFIG'
export const SET_JOB_SETTING = 'SET_JOB_SETTING'
export const UPDATE_JOB_CONFIG = 'UPDATE_JOB_CONFIG'
export const RESET_IMPORT_CONF = 'RESET_IMPORT_CONF'

export const SET_STEP = 'SET_STEP'
export const STEP_UP_DOWN = 'STEP_UP_DOWN'
export const ADD_VISITED_TAB = 'ADD_VISITED_TAB'
