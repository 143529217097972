import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const queryProps = {
  product: {
    type: String,
    default: null
  },
  card: {
    type: String,
    default: null
  },
  number: {
    type: String,
    default: null
  },
  owner: {
    type: String,
    default: null
  },
  location_id: {
    type: String,
    default: null
  },
  token: {},
  item: {},
  type: {}
}
// @deprecated
const communicationProps = {
  errorMessage: {
    type: String,
    default: null
  },
  isSuccess: {
    type: Boolean,
    default: false
  },
}

const featureProps = {
  reloadProductTypes: {
    type: [Array, Object],
    default() {
      return this.$options.$$s('productTypes', null)
    }
  },
  reloadProductTypeLang: {
    type: [Array, Object],
    default() {
      return this.$options.$$t('productTypes', {})
    }
  },
  defaultProductType: {
    type: String,
    default() {
      return this.$options.$$s('defaultProductType', 'playcard_reload')
    }
  },
  showTypeSelector: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTypeSelector', true)
    }
  },

  showTypesAboveNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTypesAboveNotice', false)
    }
  },

  onlyAuthUser: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('onlyUserCanReload', true)
    }
  },
  guestSeesDisabledForm: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('guestSeesDisabledForm', true)
    }
  },

  allowCardNumberInput: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('allowCardNumberInput', false)
    }
  },
  summaryComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.summary', 're-blank-component')
    }
  },

  loadingCardsMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingCardsMessage', 'Loading your cards...')
    }
  },
}

const formProps = {
  inputClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('inputClass', 'form-control')
    }
  },

  showQuantity: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showQuantity', false)
    }
  },
  showCardCodeInput: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showCardCodeInput', false)
    }
  },

  codePlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('codePlaceholder', '****')
    }
  },

  termsUrl: {
    type: [String, Object],
    default() {
      return this.$options.$$s('termsUrl') || this.$options.$$s('terms.url', null)
    }
  },

  bonusLabel: {
    type: String,
    default() {
      return this.$options.$$t('bonus.label', 'BONUS')
    }
  },

  showSummary: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSummary', true)
    }
  },
  summaryTitle: {
    type: String,
    default() {
      return this.$options.$$t('summary.title', 'Summary')
    }
  },
  summarySubTotalLabel: {
    type: String,
    default() {
      return this.$options.$$t('summary.subTotalLabel', 'Order Total')
    }
  },
  summaryTotalLabel: {
    type: String,
    default() {
      return this.$options.$$t('summary.totalLabel', 'Total')
    }
  },
  summaryTaxLabel: {
    type: String,
    default() {
      return this.$options.$$t('summary.taxLabel', 'Tax')
    }
  },
  termsLinkLabel: {
    type: [String, Object],
    default() {
      return this.$options.$$t('terms.link.label') || 'Terms and Conditions'
    }
  },
  cartItemEditable: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('cartItemEditable', false)
    }
  },
  // location
  useLocation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('useLocation', false)
    }
  },
  filterProductByLocation: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('filterProductByLocation', 'strict')
    }
  },
  hideOtherFieldsOnNoLocation: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('hideOtherFieldsOnNoLocation', true)
    }
  },
  locationInputOptions: {
    type: [Object],
    default() {
      const $t = this.$options.$$t('form.fields.location_id', {})
      const $s = this.$options.$$s('form.fields.location_id', {})
      return _.merge({}, $t, $s)
    }
  },
  cardLocationField: {
    type: [Number, String],
    default() {
      return this.$options.$$s('cartLocationField', 'location_id')
    }
  },
  filterCardByLocation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('filterCardByLocation', true)
    }
  },
  rememberLocation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('rememberLocation', true)
    }
  },
  showProductSelector: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showProductSelector', true)
    }
  },
  productLabelFormat: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('productLabelFormat') || this.$options.$$t('productLabelFormat') || null
    }
  },
  productsFilters: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$s('products.filters', null)
    }
  },
}
const modalProps = {

  hasHelpNumberLink: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('hasHelpNumberLink', true)
    }
  },
  helpNumberLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('helpNumberLinkLabel', 'What is this?')
    }
  },

  numberInfoModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalTitle', 'Card Number')
    }
  },
  numberInfoModalHeader: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalHeader', null)
    }
  },
  numberInfoModalFooter: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalFooter', 'The Card number can be found in the email that was sent after the purchase was done or under the \'My Cards\' section of your Account.')
    }
  },
  numberInfoModalImgSrc: {
    type: String,
    default() {
      return this.$options.$$s('numberInfoModalImgSrc', null)
    }
  },

  hasHelpCodeLink: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$t('hasHelpCodeLink', true)
    }
  },
  helpCodeLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('helpCodeLinkLabel', 'What is this?')
    }
  },

  codeInfoModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalTitle', 'CVV or Card Code')
    }
  },
  codeInfoModalHeader: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalHeader', null)
    }
  },
  codeInfoModalFooter: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalFooter', 'The CVV or Card Code can be found in the email that was sent after the the card was claimed by you.')
    }
  },
  codeInfoModalImgSrc: {
    type: String,
    default() {
      return this.$options.$$s('codeInfoModalImgSrc', null)
    }
  },
}

const componentProps = {
  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Reload a card')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', null)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', false)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', null)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', true)
    }
  },

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Your order is being processed...')
    }
  },

  addingCardMessage: {
    type: String,
    default() {
      return this.$options.$$t('addingCardMessage', 'Adding card to cart')
    }
  },

  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', true)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormFieldHint', true)
    }
  },

  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('submitButtonTitle', 'Add to cart')
    }
  },
  submitButtonTitleIncludeAmount: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('submit.buttonTitle.withAmount', true)
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submitButtonClasses', null)
    }
  },

  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', false)
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateFormFieldOnDirty', false)
    }
  },
  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  }
}

const authProps = {
  authOptions: {
    type: [Object],
    default() {
      const $s = this.$options.$$s('auth', {})
      const $t = this.$options.$$t('auth', {})
      return _.merge({}, $t, $s)
    }
  }
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...queryProps,
  ...communicationProps,
  ...featureProps,
  ...componentProps,
  ...formProps,
  ...modalProps,
  ...authProps
}
export default props
