/*global _ */
// import settings from '../lib/settings'
export default {
  settings: null,
  beforeCreate() {
    let settings = null
    const propSettings = _.get(this.$options.propsData || {}, 'settings')
    if (propSettings) {
      settings = propSettings
    } else {
      settings = this.$options && this.$options.settings
    }
    if (!settings) {
      settings = this.$parent && this.$parent.settings
    }
    this.$$setSettings(settings)
  },
  computed: {
    $$$s() {
      return this.$$s()
    }
  },
  methods: {
    $$s(path, defaultValue, ...rest) {
      return this.$options.$$s(path, defaultValue, ...rest)
    },
  }
}
