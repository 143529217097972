/*global _ */
import {
  name,
  mixins,
  props,
  data,
  defaultFormFields,
  validationsRules,
  defaultSections,
  parseValidations,
  parseValidationSetup,
  getAllProducts,
  EventBus,
} from './config'

export default {
  name,
  props,
  mixins,
  events: 'playcard.reload',
  data() {
    return {
      ...data,
      fields: {
        reloadType: this.type || null,
        cardType: null,
        terms: false,

        card: this.card || null,
        number: this.number || null,
        product: this.product || null,
        custom_amount: this.custom_amount || null,
        location_id: this.location_id || null,

        cvv: null,
        quantity: 1,
      },
      loginEventSpace: 'reload.login',
      showingLoginForm: false
    }
  },

  validations() {
    return {
      fields: this.applyValidations()
    }
  },

  computed: {
    showForm() {
      return (this.onlyAuthUser && this.authenticated) || this.guestSeesDisabledForm
    },
    showFormDisabled() {
      return this.showForm && !this.authenticated && this.guestSeesDisabledForm
    },
    disabledFormClass() {
      return [{
        disabled: this.showFormDisabled
      }];
    },
    reloadTypes() {
      return this.reloadProductTypes || {}
    },
    firstReloadType() {
      return _.head(_.keys(this.reloadTypes))
    },
    reloadCardTypes() {
      return _.filter(_.map(this.reloadTypes, 'type'))
    },
    hasMultiTypes() {
      return _.size(this.reloadTypes || {}) > 1
    },
    showReloadTypeSelector() {
      return this.hasMultiTypes && this.showTypeSelector
    },
    showReloadTypeSelectorOnTop() {
      return this.showReloadTypeSelector && this.showTypesAboveNotice
    },
    showReloadTypeSelectorOnBottom() {
      return this.showReloadTypeSelector && !this.showTypesAboveNotice
    },
    reloadTypeOptions() {
      const lang = this.reloadProductTypeLang || {}
      return _.mapValues(this.reloadTypes, (item, key) => {
        item.label = _.get(lang, `${key}.label`, item.label || _.startCase(key))
        return item
      })
    },
    userCards() {
      return this.allCards
    },
    userCardType() {
      const reloadType = this.fields.reloadType
      return _.get(this.reloadTypes, `${reloadType}.type`)
    },
    userCardsOfType() {
      let cards = this.showTypeSelector ? _.get(this.allCardsTyped, this.userCardType, {}) : this.userCards
      const location_id = this.fields.location_id
      if (location_id && this.useLocation && this.filterCardByLocation) {
        cards = _.filter(cards, card => _.get(card, this.cardLocationField) === location_id)
      }
      return cards
    },
    showAuth() {
      return !this.authenticated && this.onlyAuthUser
    },

    hasUserCards() {
      return this.authenticated && !_.isEmpty(this.userCardsOfType)
    },
    showProductsBox() {
      return ((this.showCardCodeInput && !this.$v.fields.cvv.$invalid && !this.$v.fields.number.$invalid)
        || (!this.showCardCodeInput && !this.$v.fields.number.$invalid)) && !_.isEmpty(this.proposalCards)
    },
    proposalCards() {
      const reloadType = this.fields.reloadType
      let products = this.productsTyped[reloadType] || this.products || []
      const filterByIds = this.filterByProducts
      if (!_.isEmpty(filterByIds) && _.isArray(filterByIds)) {
        products = _.filter(products, p => filterByIds.includes(p.productId))
      }
      const location = this.fields.location_id
      const filters = {}
      if (this.fields.location_id && this.useLocationFilter) {
        if (this.useStrictLocationFilter) {
          filters.location = {location, strict: true}
        } else {
          filters.location = location
        }
      }
      products = this.applyPointsOnProducts(
        this.autoApplyFilterOnProducts(products, filters)
      )
      const params = {products, vm: () => this}
      this.$$applyDataFilters('reload.playcard.prepare.products', params)
      return params.products
    },
    showSummaryBox() {
      return !this.showFormDisabled && this.showSummary && !_.isEmpty(this.selectedProduct)
    },
    isFormValid() {
      return !this.isAnyInvalid
    },
    disabledSubmit() {
      return this.isAnyInvalid
    },
    disabledInputs() {
      return this.showReloadTypeSelector && !this.fields.reloadType
    },

    infoTitle() {
      return this.isNumberInfo ? this.numberInfoModalTitle : this.codeInfoModalTitle
    },
    infoHeaderText() {
      return this.isNumberInfo ? this.numberInfoModalHeader : this.codeInfoModalHeader
    },
    infoFooterText() {
      return this.isNumberInfo ? this.numberInfoModalFooter : this.codeInfoModalFooter
    },
    infoImgSrc() {
      return this.isNumberInfo ? this.numberInfoModalImgSrc : this.codeInfoModalImgSrc
    },

    cardInputAttrs() {
      const defaults = {label: 'Card number', placeholder: 'XXXX XXXX XXXX XXXX'}
      if (!this.allowCardNumberInput) {
        defaults.blankOption = 'Select a card'
        defaults.options = this.userCardsOfType
        defaults.reduceValue = '{{id}}'
        defaults.reduceLabel = '{{number}}'
      }
      const attrs = this.getFieldProps('card', {defaults})
      return attrs
    },
    selectedUserCard() {
      const id = this.fields.card
      const number = this.fields.number
      return _.find(this.userCards, c => {
        return c.id === id || c.number === number
      })
    },
    localCart() {
      const card = this.selectedUpsellProduct || this.selectedProduct || {price: 0, tax: 0}
      // this.$$debugLog({card})
      const quantity = _.toFinite(this.fields.quantity) || 1
      const isCustom = this.isCustomAmountProduct
      const price = isCustom ? _.toFinite(this.fields.custom_amount) : _.toFinite(card.price)
      const cart = {
        card,
        subTotal: price * quantity,
        tax: _.toFinite(card.tax) * quantity,
        total: (price * quantity) + (_.toFinite(card.tax) * quantity)
      }
      // this.$$debugLog({cart})
      return cart
    },
    summaryComponentName() {
      return this.summaryComponent || 're-blank-component'
    },
    authAttrs() {
      return _.merge({
        title: 'Sign in',
        showTitle: false,
        hideTitleOnForm: false,
        hideProductsOnForm: false,
        user: {
          show: false,
          showTitle: false,
          title: 'Signed in as {{first_name}} {{last_name}}'
        },
        buttons: {
          logout: {
            show: true,
            title: 'Sign Out',
            attrs: {}
          },
          back: {
            attrs: {},
            title: 'Back',
            show: false
          }
        }
      }, this.authOptions || {})
    },
    showLocationsInput() {
      return this.useLocation
    },
    useLocationFilter() {
      return this.showLocationsInput && this.filterProductByLocation
    },
    useStrictLocationFilter() {
      return this.filterProductByLocation === 'strict'
    },
    hideNonLocationFields() {
      return !this.fields.location_id && this.hideOtherFieldsOnNoLocation
    },
    locationInputContainerAttrs() {
      return {
        class: this.getFieldContainerClasses('location_id')
      }
    },
    locationInputAttrs() {
      const defaults = this.locationInputOptions || {}
      if (!_.has(defaults, 'blankOption')) {
        defaults.blankOption = 'Select location'
      }
      defaults.context = this
      return this.getLocationFieldProperties('location_id', {defaults})
    },
  },
  watch: {
    'fields.product'(val) {
      this.selectedProduct = this.proposalCards.find(x => val && x.id === val)
      EventBus.$emit('card.reload.select-product', this.selectedProduct)
      EventBus.$emit('card.reload.select-product-with-location', {
        product: this.selectedProduct,
        location_id: this.fields.location_id,
      })
      this.emitSlim('select-product', this.selectedProduct)
      this.resetCustomAmountProduct(this.proposalCards, val)
    },
    'fields.card'(val, oldVal) {
      const card = this.selectedUserCard
      if (card && this.useLocation) {
        const id = this.fields.location_id = _.get(card, this.cardLocationField)
        EventBus.$emit('card.reload.select-location', id)
        this.emitSlim('select-location', id, this)
      }
      EventBus.$emit('card.reload.select-card', card)
      this.emitSlim('select-card', card, this)
    },
    'fields.reloadType'(val, oldVal) {
      if (val && oldVal) {
        this.fields.product = this.product
        this.fields.number = this.probeInitCardNumber()
        this.fields.card = this.probeInitCardId()
      }
    },
    'fields.location_id'(val) {
      if (val) {
        const card = this.selectedUserCard || {}
        if (_.get(card, this.cardLocationField) !== val) {
          this.fields.number = null
          this.fields.card = null
        }
        if (this.showLocationsInput && this.useLocationFilter) {
          const product = this.selectedProduct
          if (product) {
            // find if the selected product is not included supported by this new location
            const isNotLocationProduct = _.isEmpty(this.applyLocationFilter([product], val))
            if (isNotLocationProduct) {
              this.fields.product = null
            }
          }
        }
      }
      this.setShopLocationId(val)
    },
    userCardsOfType: {
      deep: true,
      async handler(val) {
        // Fix issue with selected card number of SELECT changes in DOM after filtered by location
        // This resets the DOM with correct card number in SELECT dropdown
        await this.$nextTick()
        this.$forceUpdate()
      }
    },
    errorMessage(val) {
      if (val) {
        this.isAddingToCart = false
      }
    },
    isSuccess() {
      this.isAddingToCart = false
    },
    async authenticated(val) {
      if (val) {
        this.loginFormClosed()
        await this.init()
      } else {
        this.allCards = []
        this.allCardsTyped = {}
        this.initFields(true)
      }
    },
    showSummaryBox(value) {
      this.emitSlim('show-summary', value, this)
    }
  },
  async created() {
    this.setupProducts()
    this.isAddingToCart = false
    this.setupForm(validationsRules, defaultFormFields)
    this.setupValidations()

    await this.pingUser()
    if (this.user) {
      await this.init()
    }
  },

  methods: {
    async init() {
      const options = {type: this.reloadCardTypes}
      this.isLoadingCards = true
      await this.fetchUser()

      const filters = {playcard: {omit: {is_locked: 1}}}
      const {allCards, allCardsTyped} = await this.fetchCards(options, filters)
      this.allCards = allCards
      this.allCardsTyped = allCardsTyped

      this.isLoadingCards = false
      await this.$nextTick()

      this.initFields()
    },
    initFields(isReset) {
      this.fields.product = isReset ? null : this.product
      this.fields.custom_amount = isReset ? null : this.custom_amount
      this.fields.reloadType = !this.hasMultiTypes ? this.firstReloadType : null
      this.fields.number = isReset ? null : this.probeInitCardNumber()
      this.fields.card = isReset ? null : this.probeInitCardId()

      if (!isReset) {
        const preferredLocation = _.get(this.userDataMeta, 'preferred_location_id')
        const shopLocation = this.shopLocationId
        const fieldLocation = this.fields.location_id
        let locationId = preferredLocation
        if (this.rememberLocation && (shopLocation || fieldLocation)) {
          locationId = shopLocation || fieldLocation
        } else {
          if (this.selectedUserCard) {
            locationId = _.get(this.selectedUserCard, this.cardLocationField)
          } else {
            locationId = this.location_id || preferredLocation
          }
        }
        this.fields.location_id = locationId
      } else {
        this.fields.location_id = null
      }

      this.resetCustomAmountProduct(this.proposalCards)
      this.$v.$reset()
    },
    probeInitCardId() {
      return this.card || this.getCardIdFromNumber(this.number)
    },
    probeInitCardNumber() {
      return this.number || this.getCardNumberFromId(this.card)
    },
    getCardIdFromNumber(number) {
      const card = _.find(this.allCards, card => card.number == number) || {}
      return card.id
    },
    getCardNumberFromId(id) {
      const card = _.find(this.allCards, card => card.id == id) || {}
      return card.number
    },
    getFormExtraClasses() {
      return []
    },
    async addToCart() {
      EventBus.$emit('buy.card-reload.before.add-to-cart')
      this.$v.$touch()
      this.formSubmitting = true
      if (this.isFormValid) {
        this.isAddingToCart = true
        let card = {}
        if (this.authenticated && this.hasUserCards) {
          card = this.selectedUserCard
        } else {
          card = {number: this.fields.number}
          if (this.showCardCodeInput) {
            card.cvv = this.fields.cvv
          }
        }
        const payload = {card, product: this.selectedProduct, quantity: this.fields.quantity}
        const added = await this.addCartItem(this.getCartItem())
        if (added) {
          const actioned = this.isDesignerMode ? false : await this.executeCustomAddToCartAction()
          this.$emit('addToCart', payload)

          if (this.isDesignerMode && actioned) {
            this.isAddingToCart = false
          }
          if (!this.isDesignerMode && !actioned) {
            EventBus.$emit('buy.card-reload.add-to-cart', payload)
            this.isSubmitting = true
          }
        } else {
          this.isAddingToCart = false
        }
      }
    },
    whatIsNumber() {
      this.showInfoModal = true
      this.isNumberInfo = true
    },
    whatIsCode() {
      this.showInfoModal = true
      this.isNumberInfo = false
    },
    closeInfoModal() {
      this.showInfoModal = false
      this.isNumberInfo = false
    },
    getSummarySection() {
      const $s = this.$$s('sections', {})
      const $t = this.$$t('sections', {})
      const merged = _.merge({}, $t, $s)
      const section = _.isEmpty(merged) ? defaultSections : merged

      return this.parseDynamicSections(section, {})
    },
    getCartItem() {
      const card = this.selectedUserCard
      const type = this.fields.reloadType || 'playcard_reload'
      const id = _.uniqueId(`${type}_` + (new Date().valueOf()))

      const otherOptions = {}
      if (this.isCustomAmountProduct) {
        otherOptions[this.customAmountCartItemProperty] = this.fields.custom_amount
      }

      return {
        id,
        type,
        editable: this.cartItemEditable,
        total: this.selectedProduct.price * (this.fields.quantity || 1),
        count: this.fields.quantity || 1,
        payload: {
          product_id: this.fields.product,
          options: {
            number: card.number,
            location_id: this.fields.location_id,
            ...otherOptions
          },
        },
        attributes: {
          product: this.selectedProduct,
          card: card,
          number: card.number,
        },
      }
    },
    getComponentExtraClasses() {
      return [
        {
          'showing-login-form': this.showingLoginForm
        }
      ]
    },
    loginPlugInit() {
      this.showingLoginForm = true
    },
    loginFormClosed() {
      if (this.showingLoginForm) {
        this.showingLoginForm = false
        const form = this.$refs.loginPlug
        if (form) {
          form.hideDemand()
        }
      }
    },
    getFieldClasses(field, source = {}, mergeData = {}) {
      const data = {...source}
      const path = `form.fields.${field}`
      const def = this.$$s(`${path}.classes`, this.$$s(`${path}.attr.classes`))
      const classes = this.parseReactiveAttrs(def, data || {})
      return [
        classes,
        mergeData
      ]
    },
    getProductFieldProps(card) {
      const props = this.getFieldProps('product') || {}
      const classes = _.concat(_.castArray(props.class || []), [{
        active: this.fields.product === card.id
      }])
      const buildProps = {
        key: card.id,
        id: 'amount' + card.id,
        radioValue: card.id,
        // validations: this.validations.product,
        label: this.productLabelFormat ? this.$$templify(this.productLabelFormat, card) : card.title,
        'class': this.getFieldClasses('product', card, classes)
      }

      return {
        ...props,
        ...buildProps
      }
    },
    setupValidations() {
      const items = parseValidationSetup(
        this.parseFormValidations(validationsRules, this.$$s('form.fields')),
        this
      )
      if (!this.isCustomAmountProduct) {
        items.custom_amount.items = {}
      } else {
        this.setupCustomAmountValidations(items.custom_amount.items)
      }
      if (!this.useLocation) {
        items.location_id.items = {}
      } else {
        if (this.validateFailingLocation && this.setupFailingLocationValidations) {
          this.setupFailingLocationValidations(items.location_id.items)
        }
      }
      this.validations = items
    },
    applyValidations() {
      this.setupValidations()
      let items = parseValidations(this.validations)
      return items
    },
    setupProducts() {
      this.products = this.parseProducts(getAllProducts())
      this.productsTyped = _.groupBy(this.products, 'type')
    },
    parseProducts(products) {
      products = _.cloneDeep(_.values(products))
      const additionalProperties = this.$$s('product.additionalProperties') || {}
      _.forOwn(products, product => {
        if (!_.has(product, 'upsellProduct')) {
          product.upsellProduct = null
        }
        _.forOwn(additionalProperties, (value, key) => {
          if (!_.has(product, key)) {
            product[key] = value
          }
        })
      })
      return products
    },
    waitForUserCards() {
      return new Promise(resolve => {
        const getCards = () => {
          const cards = this.userCards
          if (_.isEmpty(cards)) {
            setTimeout(getCards, 200)
          } else {
            resolve(cards)
          }
        }
        getCards()
      })
    },
    async getCardsLocations() {
      const cards = await this.waitForUserCards()
      return _.reduce(cards, (locations, card) => {
        const location = _.get(card, 'location.data')
        if (location) {
          locations[location.id] = location.display_name || location.name
        } else {
          const id = card.location_id
          if (id) {
            const location = _.get(window.addiesaas, `locations.${id}`)
            locations[id] = location.display_name || location.name
          }
        }
        return locations
      }, {})
    }
  }
}
