<template lang="pug">
  .b-verificode.m-b-35
    .b-verificode__group
      input.b-verificode__input(
        v-for="i in 3"
        v-model="code[i - 1]"
        :ref="`code`"
        @input="input(i - 1)"
        @focus="focus(i - 1)"
        type="number" min="0" max="9" placeholder="0"
      )
    .b-verificode__divider -
    .b-verificode__group
      input.b-verificode__input(
        v-for="i in 3"
        v-model="code[i + 2]"
        :ref="`code`"
        @input="input(i + 2)"
        @focus="focus(i + 2)"
        type="number" min="0" max="9" placeholder="0"
      )

</template>

<script>
import { required, integer, between } from 'vuelidate/lib/validators'

const CODE_LENGTH = 6

export default {
  name: 'onboarding-verificode',

  data: () => ({
    code: ['', '', '', '', '', ''],
  }),

  validations: {
    code: {
      $each: {
        required,
        integer,
        between: between(0, 9),
      }
    },
  },

  watch: {
    code: {
      deep: true,
      handler() {
        this.$emit('change', this.code.join(''))
      },
    },
  },

  methods: {
    focus(index) {
      this.$set(this.code, index, '')
    },

    input(index) {
      if (index + 1 < CODE_LENGTH) {
        this.$refs.code[index + 1].focus()
      } else {
        this.$refs.code[index].blur()
      }

      this.$v.code.$touch()

      this.backendError = null
    },
  }
}
</script>
