/*global _ */
import {Vue} from '~/addiesaas'
import {templify, hasThisTemplate} from './utils'
const that = {
  $$templify: templify
}
const ad = window.addiesaas || (window.addiesaas = {})
export const STLD ='frontend' // setting TLD
export const all = _.get(ad, `${STLD}.settings`, {})

export const factory = function(space, mergeStrategy = 'shared') {
  if (_.isFunction(space)) {
    return space
  }
  const me = this || that
  let slice = space ? {} : all
  if (mergeStrategy) {
    slice = _.merge({}, slice, _.get(all, mergeStrategy, {}))
  }
  if (_.isString(space)) {
    space = _.get(all, space, all)
  }

  let dynamicMerge = _.get(space, '$merge')
  if (dynamicMerge) {
    if (_.isString(dynamicMerge)) {
      dynamicMerge = dynamicMerge.replace(' ', '').split(',')
    }
    if (!_.isEmpty(dynamicMerge)) {
      const items = _.clone(dynamicMerge)
      dynamicMerge = {}
      _.forOwn(items, (i) => {
        dynamicMerge = _.merge({}, dynamicMerge, _.get(all, i, {}))
      })
    }
  }
  if (_.isEmpty(dynamicMerge) || (!_.isObject(dynamicMerge) && !_.isArray(dynamicMerge))) {
    dynamicMerge = {}
  }

  slice = _.merge({}, slice, dynamicMerge, space || {})

  return (path, defaultValue = '', data = {}) => {
    let value = path ? _.get(slice, path, defaultValue) : slice
    if (this && hasThisTemplate(value)) {
      value = me.$$templify(value, data)
    }
    return value
  }
}

export const loadSettings = function(path, key, mergeStrategy = 'shared') {
  const getSettings = factory(path, mergeStrategy)
  return getSettings(key, {})
}

export const root = factory()

export const setSettings = function(space, mergeStrategy = 'shared') {
  this.$options.$$s = factory.call(this, space, mergeStrategy)
}

Vue.prototype.$$rootSettings = root
Vue.prototype.$$setSettings = setSettings

export default {
  factory,
  loadSettings,
  all,
  root
}

