/* global _ */
import {name, components, mixins, props, data, EventBus} from './config'
import {getParentSlots} from '../../../../lib/vue'

export default {
  name,
  components,
  mixins,
  props,
  events: 'shopping-cart',
  data() {
    return {
      ...data,
      items: null,
      order: null,
      customStep: null,
      disableBillingForm: false,
      viewPaymentDetails: false,
      isCartValid: false
    }
  },

  watch: {
    cartStep(stepName) {
      this.lazyScrollTo('.re-page')
      this.emitSlim('step', stepName, this)
      this.emitSlim('step.' + stepName, this)
    },
    showCartEmpty(isEmpty) {
      this.emitSlim('empty', isEmpty, this)
    },
    isCartValid(valid) {
      this.emitSlim('valid', valid, this)
    },
    isCartLocationValid(valid) {
      if (!valid) {
        this.isCartValid = valid
        this.valid = valid
        this.emitSlim('valid', valid, this)
      }
    },
    async cartItemCount(val, oldVal) {
      if (oldVal && val > oldVal) {
        await this.validateCart(true)
      }
    },
    isSelectedLocationFailing: {
      immediate: false,
      async handler(isFailing) {
        await this.handleFailingLocation(isFailing)
      }
    }
  },

  created() {
    this.setCostDetails({})
    this.allowLocationCheckout(this.useLocationBasedCart)
    this.customStep = this.$$debug && this.step
    this.setCartStep(this.customStep || 'cart')

    EventBus.$on('promise.shop.order.init', this.mayAddLocationToOrder)

    EventBus.$on('cart.init-validation', () => {
      this.isCartValid = false
    })

    EventBus.$on('cart.validate.before', (p, o) => this.mayAddLocationToOrder(null, p, o))

    EventBus.$on('cart.unauthenticated', () => {})

    EventBus.$once('cart.validated', x => {
      this.setCostDetails(x)
      this.setValidationError()
    })

    EventBus.$on('cart.validated', async () => {
      this.isCartValid = true
      if (this.isSelectedLocationFailing) {
        await this.$nextTick()
        await this.handleFailingLocation(this.isSelectedLocationFailing)
      }
    })

    EventBus.$on('cart.validate', async () => {
      this.validateRepeatCount = 0
      await this.validateCart()
    })

    EventBus.$on('cart.paying', () => {
      this.items = this.currentCartItems
    })
    EventBus.$on('cart.paid', order => {
      //this.stopTimer()
      this.order = order
      this.clearCartItems()
    })
    EventBus.$on('showPaymentDisabledDetails', () => {
      this.customStep = 'billing'
      this.disableBillingForm = true
      this.viewPaymentDetails = true
      this.setCartStep('billing')
    })
  },

  computed: {
    showCartEmpty() {
      return !this.customStep && _.isEmpty(this.order) && this.currentCartIsEmpty
    },
    showCartStep() {
      return this.isCustomCartStep('cart') || (this.cartStep === 'cart' && !this.currentCartIsEmpty)
    },
    showBillingStep() {
      return this.isCustomCartStep('billing') || (this.cartStep === 'billing' && !this.currentCartIsEmpty)
    },
    showFinalStep() {
      return this.isCustomCartStep('final') || (this.cartStep === 'final' && !_.isEmpty(this.order))
    }
  },

  methods: {
    isCustomCartStep(step) {
      return this.customStep && this.customStep === step
    },
    getChildSlots(type) {
      const allSlots = getParentSlots(this)
      const slots = _.reduce(allSlots, (slots, items) => {
        return {
          ...slots,
          ..._.pickBy(items, (i, k) => _.startsWith(k, `${type}-`)),
        }
      }, {})
      return slots
    },
    async handleFailingLocation(isFailing) {
      if (this.isValidatingCart) {
        return
      }
      const isValid = !isFailing
      this.setCartLocationValid(isValid)
      if (isValid) {
        this.setValidationError()
        if (!this.valid) {
          await this.validateCart(true)
        }
      } else {
        this.setCartValid(isValid)
        if (this.failingLocationMessage) {
          this.setValidationError(this.failingLocationMessageFormatted)
        }
      }
    },
    getComponentExtraClasses() {
      return [
        {
          'is-designer-mode': this.isDesignerMode,
          'is-location-failing': this.isSelectedLocationFailing
        }
      ]
    }
  },
}
