import {setTimeoutAsync} from "../../../../lib/utils";

const CART_ITEMS = [
  {
    "id": "giftcard_0001",
    "title": "Gift Card $100",
    "type": "giftcard",
    "total": 100,
    "payload": {
      "product_id": 1,
      "options": {
        "recipient_email": "greg@rocketeffect.com",
        "recipient": {
          "first_name": "Greg",
          "last_name": "Moreno",
          "phone": ""
        }
      },
      "count": 1
    },
    "attributes": {
      "product": {
        "title": "Gift Card $100",
      }
    },
    "deletable": true,
    "groupTitle": "Gift Cards"
  },
  {
    "id": "playcard_0001",
    "title": "Play Card $25",
    "type": "playcard",
    "total": 50,
    "payload": {
      "product_id": 2,
      "count": 2
    },
    "attributes": {
      "product": {
        "title": "Play Card $25",
      }
    },
    "deletable": true,
    "groupTitle": "Play Cards"
  },
]

const costDetails = {
  "total": 0,
  "deposit": 0,
  "price": 0,
  "tax": 0,
  "fees": 0,
  "fees_tax": 0,
  "shipping_fees": 0,
  "shipping_fees_tax": 0,
  "price_discount": 0,
  "tax_discount": 0,
  "fee_discount": 0,
  "fee_tax_discount": 0,
  "shipping_fee_discount": 0,
  "shipping_fee_tax_discount": 0,
  "remaining": 0,
  "payment_required": true,
  "location_id": "qnwmkv5704blag6r",
  "discounts": {
    "data": []
  }
}

const loadSampleCartItems = async function () {
  // await this.clearCartItems()
  await setTimeoutAsync(0)
  const items = this.cartItems
  if (!_.size(items)) {
    for(let item of CART_ITEMS) {
      if (!this.cartItems[item.id]) {
        await this.addCartItem(item)
      }
    }
  }
  await this.$nextTick()
  await calculateSummary.call(this)
}

const calculateSummary = async function () {
  await setTimeoutAsync(0)
  const items = this.cartItems
  if (!_.size(items)) {
    this.order = null
    await this.setCostDetails(null)
    return
  }

  const summary = _.cloneDeep(costDetails)
  _.forOwn(items, item => {
    summary.price += item.total || 0
    summary.total = summary.price
    summary.deposit = summary.price
  })

  await this.setCostDetails(summary)
  this.isCartValid = true
  await this.setCartValid(true)
  this.order = {
    user: {
      data: {
        email: 'gerg@rocketeffect.com'
      }
    },
    invoice_url: 'https://rocketeffect.com/store/invoice.pdf'
  }
}

const eventListeners = function () {
  window.VueEventBus.$on('cart.cleared', async () => {
    await calculateSummary.call(this)
  })
  window.VueEventBus.$on('cart.item.removed', async () => {
    await calculateSummary.call(this)
  })
  window.VueEventBus.$on('cart.item.updated', async () => {
    await calculateSummary.call(this)
  })
  window.VueEventBus.$on('cart.item.added', async () => {
    await calculateSummary.call(this)
  })
}

export default {
  async mounted() {
    if (this.isDesignerMode) {
      await this.$nextTick()
      eventListeners.call(this)
      await loadSampleCartItems.call(this)
    }
  }
}
